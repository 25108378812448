import React from 'react';
import styled from 'styled-components';

const TwoThreeImageContainer = styled.div`
  position: relative;
  overflow: hidden;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 150%;
  }
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const TwoThreeImage = ({ image, alt }) => {
  return (
    <TwoThreeImageContainer>
      <div>
        <img src={image} alt={alt} loading="eager" />
      </div>
    </TwoThreeImageContainer>
  );
};
